<template lang="pug">
.om-sticky-bottom
  .container.h-100
    .d-flex.align-items-center.h-100
      om-button(v-if="showBackBtn" ghost @click="$router.go(-1)") {{ $t('back') }}
      .ml-auto
        .d-flex.align-items-center(v-if="showGoToTemplateBtn")
          .font-weight-semibold.mr-2 {{ $t('onboarding.wizard.bottom.question') }}
          om-link(primary withIconRight)
            span(@click="$emit('templates')") {{ $t('onboarding.wizard.bottom.button') }}
</template>

<script>
  export default {
    props: {
      showBackBtn: {
        type: Boolean,
        default: true,
      },
      showGoToTemplateBtn: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="sass">
  .om-sticky-bottom
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    height: 3.5rem
    background-color: white
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1)
    color: #505763
    z-index: 10
</style>
